<template>
  <div class="Part">
    <div class="input">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
      <el-row style="padding: 5px 5px 5px 5px;">
        &nbsp;&nbsp;套餐名称 &nbsp;&nbsp;
        <el-input v-model="scFrom.name" @input="scMethod" clearable size="mini" style="width:125px"></el-input>&nbsp;&nbsp;套餐状态 &nbsp;&nbsp;
        <el-select v-model="scFrom.status" size="mini" style="width:125px" @change="scMethod">
          <el-option label="正常" value="0"></el-option>
          <el-option label="注销" value="1"></el-option>
        </el-select>
      </el-row>
    </div>
    <div style="text-align:center;">
      <el-table :data="tableData" border :height="tableHeight" highlight-current-row>
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="sppPackName" label="套餐名称" align="center"></el-table-column>
        <el-table-column prop="sppCycleType" label="有效期" align="center"></el-table-column>
        <el-table-column prop="sppPackTime" label="周期类型" align="center"></el-table-column>
        <el-table-column prop="sppPrice" label="套餐价格(元)" align="center"></el-table-column>
        <el-table-column prop="sppUpSpeed" label="上行带宽(KB/s)" align="center"></el-table-column>
        <el-table-column prop="sppDownSpeed" label="下行带宽(KB/s)" align="center"></el-table-column>
        <el-table-column prop="sppComment" label="备注" align="center"></el-table-column>
        <el-table-column label="操作" align="center" min-width="130px">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleChange(scope.$index, scope.row)">修改</el-button>
            <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange($event,'pageSize',getData,'clickrow')"
        @current-change="currentChange($event,'currentPage',getData,'clickrow')"
      ></el-pagination>
    </div>
    <!-- 添加 修改 对话框 -->
    <el-dialog
      :title="addFrom.title"
      :visible.sync="addDia"
      width="400px"
      :close-on-click-modal="false"
      @close="addDiaClose"
       v-loading="loading"
    >
      <el-form ref="addFrom" :model="addFrom" :rules="addFromRule" label-width="110px" size="mini">
        <el-form-item label="套餐名称" prop="sppPackName">
          <el-input v-model="addFrom.sppPackName"></el-input>
        </el-form-item>
        <el-form-item label="周期类型" prop="sppCycleType">
          <el-select v-model="addFrom.sppCycleType" style="width:100%;">
            <el-option label="月" value="月"></el-option>
            <el-option label="天" value="天"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效期" prop="sppPackTime">
          <el-input v-model.number="addFrom.sppPackTime"></el-input>
        </el-form-item>
        <el-form-item label="套餐价格(元)" prop="sppPrice">
          <el-input v-model="addFrom.sppPrice"></el-input>
        </el-form-item>
        <el-form-item label="上行带宽(KB/s)" prop="sppUpSpeed">
          <el-input v-model.number="addFrom.sppUpSpeed"></el-input>
        </el-form-item>
        <el-form-item label="下行带宽(KB/s)" prop="sppDownSpeed">
          <el-input v-model.number="addFrom.sppDownSpeed"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addFrom.sppComment"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="addDia = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "package",
  data() {
    let checkMoney = (rule,value,callback)=> {
      let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
      if (!value) {
        return callback(new Error('金额不能为空'))
      }
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的金额'))
      }
    }
    return {
      loading:false,
      addDia: false,

      scFrom: {
        name: "",
        status: "0"
      },
      addFromRef: ["addFrom"],
      addFrom: {
        title: "",
        sppPackName: "",
        sppCycleType: "",
        sppPackTime: "",
        sppPrice: "",
        sppUpSpeed: "",
        sppDownSpeed: "",
        sppProgrammeState: "0",
        sppComment: ""
      },
      addFromRule: {
        sppPackName: [
          { required: true, message: '请输入套餐名', trigger: 'blur' },
        ],
        sppCycleType: [
          { required: true, message: '请选择周期', trigger: 'change' },
        ],
        sppPackTime: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { type: 'number', message: '必须为数字值',trigger: 'blur'}
        ],
        sppPrice: [
          { validator: checkMoney , trigger: 'blur' },
        ],
        sppUpSpeed: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { type: 'number', message: '必须为数字值',trigger: 'blur'}
        ],
        sppDownSpeed: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { type: 'number', message: '必须为数字值',trigger: 'blur'}
        ],
      },

      tableData: [],
      tableHeight: window.innerHeight - 185,
      clickrow: "",

      // 首页分页
      total: 0,
      pageSize: 10,
      currentPage: 1
    };
  },
  methods: {
    //打开添加修改对话框
    add() {
      this.addFrom.title = "添加";
      this.addDia = true;
    },
    // 保存对话框
    addSave() {
      
      if (this.submitForm(this.addFromRef, this)){
        this.loading = true
        if (this.addFrom.title == "添加") {
          this.$axios({
            url: `/common2/packagePlans`,
            method: "post",
            data: this.addFrom
          }).then(res => {
            this.$message.success("添加成功");
            this.addDia = false;
            this.getData();
            this.loading = false
          }).catch(err => {
            this.loading = false
            this.isError(err,this)
          });
        } else {
          this.$axios({
            url: `/common2/packagePlans/${this.clickrow.sppId}`,
            method: "put",
            data: this.addFrom
          }).then(res => {
            this.$message.success("修改成功");
            this.addDia = false;
            this.loading = false
            this.getData();
          }).catch(err => {
            this.loading = false
            this.isError(err,this)
          });
        }
      }
    },
    //修改
    handleChange(index, row) {
      console.log(index, row);
      this.clickrow = row;
      this.addFrom.title = "修改";
      this.addFrom.sppPackName = row.sppPackName;
      this.addFrom.sppCycleType = row.sppCycleType;
      this.addFrom.sppPackTime = row.sppPackTime;
      this.addFrom.sppPrice = row.sppPrice;
      this.addFrom.sppUpSpeed = row.sppUpSpeed;
      this.addFrom.sppDownSpeed = row.sppDownSpeed;
      this.addFrom.sppProgrammeState = row.sppProgrammeState;
      this.addFrom.sppComment = row.sppComment;
      this.addDia = true;
    },
    addDiaClose() {
      this.addFrom = {
        title: "",
        sppPackName: "",
        sppCycleType: "",
        sppPackTime: "",
        sppPrice: "",
        sppUpSpeed: "",
        sppDownSpeed: "",
        sppProgrammeState: "0",
        sppComment: ""
      };
      this.resetForm(this.addFromRef, this)
    },
    //删除
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("确认要删除数据吗？")
        .then(() => {
          this.$axios({
            url: `/common2/packagePlans/${row.sppId}`,
            method: "delete"
          }).then(res => {
            this.$message.success("删除成功");
            this.getData();
          }).catch(err => {
            this.isError(err,this)
          })
        })
        .catch(() => {});
    },
    //选择数据条数
    sizeChange(callVal, pageSize, getData, ...cleanRow) {
      this.$data[pageSize] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    //具体那一页
    currentChange(callVal, currentPage, getData, ...cleanRow) {
      this.$data[currentPage] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    scMethod() {
      this.currentPage = 1;
      this.getData();
    },
    getData() {
      this.$axios({
        url: "/common2/packagePlans",
        method: "get",
        params: {
          cursor: this.currentPage,
          limit: this.pageSize,
          sppPackName: this.scFrom.name,
          sppProgrammeState: this.scFrom.status
        }
      }).then(res => {
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    }
  },
  created() {
    this.getData();
  }
};
</script>
